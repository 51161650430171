<template>
    <div>
        <v-header showStyle="white"></v-header>

        <div class="bg-box">
            <div class="wrap contain-box">

                <!-- 车辆信息 -->
                <div class="car-box">
                    <v-title>{{ $t('info1') }}</v-title>
                    <div class="carList">
                        <template v-if="dataList && dataList.length > 0">
                            <el-checkbox-group v-model="selectCarArr" @change="changeCar">
                                <div class="item f-row-s-c" v-for="item in dataList" :key="item.id">
                                    <label class="f-row-s-c" style="width: 100%;cursor: pointer;">
                                        <el-checkbox :label="item.id"></el-checkbox>
                                        <div class="info flex1">
                                            <v-car :item="item" :showNum="false" :types="curOrderType"></v-car>
                                        </div>
                                    </label>
                                    <el-input-number class="input-num" v-model="item.num" :min="1"></el-input-number>
                                </div>
                            </el-checkbox-group>
                            <div class="paging" style="padding:20px 0;">
                                <el-pagination background layout="prev, pager, next" :total="dataCount"
                                    :page-size="pageSize" :page-count="Math.ceil(dataCount / 3)"
                                    @current-change="changePage" :current-page="pageNum">
                                </el-pagination>
                            </div>
                        </template>
                        <template v-else>
                            <div class="no-content f-row-c-c" style="height: 200px;">{{ $t('M66') }}</div>
                        </template>
                    </div>
                </div>
                <!-- 平台服务 -->
                <div class="serve-box">
                    <v-title>{{ $t('info6') }}</v-title>
                    <div class="serveList">
                        <template v-if="serveList && serveList.length > 0">
                            <el-checkbox-group class="f-row-s-c f-w" v-model="selectServeArr" @change="changeServe">
                                <div class="items f-row-s-c" v-for="item in serveList" :key="item.value">
                                    <label class="f-row-s-c" style="cursor: pointer;">
                                        <el-checkbox :label="item.value"></el-checkbox>
                                        <div class="ins f-row-s-c">
                                            <img v-if="item.serviceName == '举牌接机'"
                                                src="../../assets/images/clxx_icon_jpjj.png" />
                                            <img v-if="item.serviceName == '儿童椅'"
                                                src="../../assets/images/clxx_icon_ety.png" />
                                            <img v-if="item.serviceName != '儿童椅' && item.serviceName != '举牌接机'"
                                                src="../../assets/images/clxx_icon_qtfw.png" />

                                            <div>
                                                {{ item.serviceName || '' }}
                                                <span v-if="$i18n.locale == 'en'">${{ item.serviceAUDPrice || '0.00'
                                                    }}</span>
                                                <span v-else>¥{{ item.servicePrice || '0.00' }}</span>
                                            </div>
                                        </div>
                                    </label>
                                    <el-input-number class="input-num" v-if="item.serviceName == '儿童椅'"
                                        v-model="item.num" :min="1"></el-input-number>
                                </div>
                            </el-checkbox-group>
                        </template>
                        <template v-else>
                            <div class="no-content f-row-c-c" style="height: 200px;">{{ $t('M66') }}</div>
                        </template>
                    </div>
                </div>
                <!-- 提交 -->
                <div class="operate-box">
                    <div class="price-box f-row-b-c">
                        <div class="l-price f-row-s-c">
                            价格：
                            <span class="nums">
                                <span>{{ $i18n.locale == 'en' ? '$' : '¥' }}</span>
                                <span style="font-size: 20px;">{{ SumPrice }}</span>
                            </span>
                        </div>
                        <div class="r-price f-row-c-c" @click="handleSubmit">{{ $t('I39') }}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'carList',
    data() {
        return {
            orders: {},//订单信息
            curOrderType: 0,//当前订单类型

            selectCarArr: [],//选中的车型id列表
            selectServeArr: [],//选中的服务id列表

            serveList: [],//服务列表
            dataList: [],//车辆列表
            dataCount: 1, //总数据数量
            pageNum: 1,
            pageSize: 4,
            checked: false,
        }
    },
    created() {
        this.curOrderType = this.$route.query.type
        if (localStorage.getItem('orderInfo')) {
            this.orders = JSON.parse(decodeURIComponent(localStorage.getItem('orderInfo')))
        }
        this.getCarList()
        this.getServeList()
    },
    computed: {
        //车型价格
        CarPrice() {
            let newList = this.dataList.filter((item) => {
                return this.selectCarArr.includes(item.id)
            })
            let price = 0
            if (this.$i18n.locale == 'en') {
                //澳元
                newList.map(item => {
                    price += item.num * item.audMoney
                })
            } else {
                newList.map(item => {
                    price += item.num * item.unitPrice
                })
            }
            return price
        },
        //服务价格
        ServePrice() {
            let newList = this.serveList.filter((item) => {
                return this.selectServeArr.includes(item.value)
            })
            let price = 0
            if (this.$i18n.locale == 'en') {
                //澳元
                newList.map(item => {
                    price += item.num * item.serviceAUDPrice
                })
            } else {
                newList.map(item => {
                    price += item.num * item.servicePrice
                })
            }
            return price
        },
        //总价
        SumPrice() {
            return (this.CarPrice + this.ServePrice).toFixed(2)
        },
    },
    methods: {
        //获取车型列表 curOrderType 0专车 1包车
        getCarList() {
            const loading = this.$loading('.loading-box', '加载中', true)
            let dataParams = {}
            let params = {}
            dataParams = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }
            if (this.curOrderType == 0) {
                params = {
                    originsAddress: this.orders.setOutAddress || '',
                    destinationsAddress: this.orders.arriveOutAddress || '',
                    appointmentTime: this.orders.appointmentTime || '',
                }
            } else if (this.curOrderType == 1) {
                params = {
                    originsAddress: this.orders.setOutAddress || '',
                    hour: this.orders.vehicleDuration || ''
                }
            }
            Object.assign(dataParams, params)
            $api[this.curOrderType == 0 ? 'pageAllCarAddress' : 'listCharterTimeNew'](dataParams).then(res => {
                loading.close()
                if (res.code == 200) {
                    if (res.rows && res.rows.length > 0) {
                        res.rows.forEach(item => {
                            item.num = 1
                        })
                    }
                    this.dataList = res.rows || []
                    this.dataCount = res.total || 0
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取平台服务列表
        getServeList() {
            $api.pageAllCarService().then(res => {
                if (res.code == 200) {
                    res.rows && res.rows.forEach((item, index) => {
                        item.num = 1
                        item.value = index + 1
                    })
                    this.serveList = res.rows
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //切换分页
        changePage(e) {
            this.pageNum = e
            this.getList()
        },
        //选择车型
        changeCar(e) {
            this.selectCarArr = e
        },
        //选择服务
        changeServe(e) {
            this.selectServeArr = e
        },
        //提交
        handleSubmit() {
            if (!this.selectCarArr.length) {
                this.$message.warning(this.$t('Text17'))
                return
            }
            //车型
            let carInfoArr = []
            this.selectCarArr.forEach(item => {
                this.dataList.forEach(i => {
                    if (item == i.id) {
                        carInfoArr.push(i)
                    }
                })
            })
            //服务
            let serveInfoArr = []
            this.selectServeArr.forEach(item => {
                this.serveList.forEach(i => {
                    if (item == i.value) {
                        serveInfoArr.push(i)
                    }
                })
            })
            localStorage.setItem('orderInfo', encodeURIComponent(JSON.stringify(this.orders)))
            localStorage.setItem('selectCarInfo', encodeURIComponent(JSON.stringify(carInfoArr)))
            localStorage.setItem('selectServeInfo', encodeURIComponent(JSON.stringify(serveInfoArr)))
            this.$router.push({
                path: '/confirm', query: {
                    type: this.curOrderType,
                    // orders: encodeURIComponent(JSON.stringify(this.orders)),
                    // carList: encodeURIComponent(JSON.stringify(carInfoArr)),
                    // severList: encodeURIComponent(JSON.stringify(serveInfoArr)),
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
//复选框
::v-deep .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #CDCDCD;
    border-radius: 100%;
}

::v-deep .el-checkbox__inner::after {
    height: 10px;
    left: 7px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
}

::v-deep .el-checkbox__label {
    display: none;
}

//计步器
::v-deep .el-input-number__decrease {
    background: transparent;
}

::v-deep .el-input-number__increase {
    background: transparent;
}

.contain-box {
    padding: 35px 46px;
}

.input-num {
    height: 32px;
    line-height: 30px;
}

.carList {
    .item {
        position: relative;

        .input-num {
            position: absolute;
            right: 0;
            bottom: 40px;
        }

        .info {
            margin-left: 20px;
            padding: 40px 0;
            border-bottom: 1px solid #E4E4E4;
        }
    }
}

.box {
    background: #fff;
    height: calc(100vh - 138px);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    padding: 20px;
}

.serveList {
    margin-top: 24px;

    ::v-deep .el-checkbox {
        margin-top: 4px;
    }

    .items {
        margin-right: 40px;
        gap: 14px;
    }

    .ins {
        margin-left: 8px;
        gap: 8px;
        font-size: 18px;

        img {
            width: auto;
            height: 16px;
        }

        span {
            font-size: 20px;
            color: var(--price-color);
        }
    }
}

.operate-box {
    padding: 50px 0 100px;
    width: 632px;
}

.price-box {
    height: 74px;
    background: #f3f3f3;
    border-radius: 4px;
    overflow: hidden;

    .l-price {
        padding-left: 18px;
        font-size: 18px;
        color: #333;

        .nums {
            font-weight: bold;
            font-size: 14px;
            color: #fa692a;
        }
    }

    .r-price {
        width: 220px;
        height: 100%;
        font-size: 22px;
        color: #fff;
        background: var(--theme-color);
        cursor: pointer;
    }
}
</style>