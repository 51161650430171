<template>
  <div class="inputs f-row-b-c" :style="{ 'width': width,'height':height }">
    <el-input v-model="inputVal" :placeholder="placeholder" @input="changeAddress" @blur="blurAddress"
      clearable></el-input>

    <div class="address-box" v-if="isSearch">
      <div :loading="isLoading">
        <div class="box-list" v-if="searchList && searchList.length > 0">
          <div class="box-item" v-for="(item, index) in searchList" :key="index"
            @click="currentAddress(item.mainText, item.secondaryText)">
            <div class="main one-txt-cut">{{ item.mainText }}</div>
            <div class="all one-txt-cut">—{{ item.secondaryText }}</div>
          </div>
        </div>
        <div class="no-result f-col-c-c" v-else>
          {{ $t('info43') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
  name: "v-address",
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '58px',
    }
  },
  data() {
    return {
      inputVal: '',
      searchList: [],
      isSearch: false,
      isLoading: false,
      language: 'zh',
    };
  },
  created() {
    if (localStorage.getItem('language')) {
      this.language = localStorage.getItem('language')
    }
  },
  methods: {
    //获取焦点--地址查找
    changeAddress(val) {
      if (!!val) {
        this.isSearch = true;
        this.getAddressList(val);
      } else {
        this.isSearch = false;
        this.searchList = [];
      }
    },
    //失去焦点--未选中时清空
    blurAddress() {
      // const obj = this.searchList.find((item) => { this.inputVal === item.secondaryText + item.mainText })
      // if (!obj) {
      //   this.inputVal = ''
      // }
      setTimeout(() => {
        this.isSearch = false;
      }, 200)
    },
    //获取相似列表
    getAddressList(val) {
      if (this.language == 'zh') {
        $api.addressSearch({
          name: val
        }).then(res => {
          if (res.code === 200) {
            this.isSearch = true;
            this.isLoading = true;
            this.searchList = res.data;
          }
        }).finally(() => {
          this.isLoading = false;
        })
      } else if (this.language == 'en') {
        $api.addressSearchEnglish({
          name: val
        }).then(res => {
          if (res.code === 200) {
            this.isSearch = true;
            this.isLoading = true;
            this.searchList = res.data;
          }
        }).finally(() => {
          this.isLoading = false;
        })
      }
    },
    //选择当前地址
    currentAddress(val, all) {
      this.inputVal = all + val;
      this.$emit('inputValue', this.inputVal);
      this.isSearch = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner{
  padding: 0 !important;
  border:none !important;
}
.inputs {
  position: relative;

  input {
    width: 100%;
  }
}

.address-box {
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 2;
  padding: 0 10px;
  width: 100%;
  min-height: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 5px #ddd;

  .address-load {
    height: 100px;
  }

  .box-list {
    .box-item {
      line-height: 35px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }

    .all {
      padding-left: 10px;
      font-size: 15px;
      color: #666;
    }
  }

  .no-result {
    line-height: 100px;
    font-size: 15px;
    color: #666;
  }
}
</style>