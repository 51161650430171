<template>
    <div class="head-box" :class="'head-' + showStyle">

        <div class="head-wrap f-row-b-c">
            <!-- 左边区 -->
            <div class="l-head">
                <div class="logo" @click="goHome">
                    <img :src="require(`../assets/${showStyle == 'black' ? 'index' : 'clxx'}_icon_logo.png`)"
                        style="width:198px;cursor: pointer;" alt="">
                </div>
            </div>
            <!-- 右边区 -->
            <div class="r-head f-row-e-c">
                <div v-if="isLogin" class="nav-list f-row-e-c">
                    <div v-if="userInfo.enterpriseName" class="one-txt-cut" style="max-width:200px">
                        {{ userInfo.enterpriseName }}
                    </div>
                    <div class="line">|</div>
                    <div class="item" @click="handleBack">{{ $t("my9") }}</div>
                    <router-link to="/centre/order">
                        <div class="item" @click="navBar(7)">{{ $t('PH5') }}</div>
                    </router-link>
                </div>
                <div v-else class="nav-list f-row-e-c">
                    <div class="item" @click="$store.commit('SHOWLOGIN')">{{ $t('I3') }}</div>
                    <div class="line">|</div>
                    <div class="item" @click="$store.commit('SHOWREGISTER')">{{ $t('PH2') }}</div>
                </div>
                <div class="languages">
                    <el-select v-model="languageVal" @change="changeLanguage">
                        <el-option v-for="item in languageList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>

            </div>
        </div>

        <!-- 登录弹窗 -->
        <el-dialog :fullscreen="true" :modal="false" :visible.sync="$store.state.showLogin" :show-close="false">
            <div class="full-dialog f-row-e-c">
                <div class="full-box f-col-c-c">
                    <div class="full-close" @click="closeDialog(1)">
                        <img src="../assets/images/login_icon_close.png" alt="">
                    </div>
                    <div class="full-contain">
                        <div class="full-title f-row-s-c">
                            <div class="tabs one-txt-cut" :class="{ 'active': loginTabs == 0 }"
                                @click="changeLoginTabs(0)">
                                {{ $t('M51') }}
                            </div>
                            <div class="tabs one-txt-cut" :class="{ 'active': loginTabs == 1 }"
                                @click="changeLoginTabs(1)">
                                {{ $t('I6') }}
                            </div>
                        </div>
                        <div class="full-form">
                            <div class="form-input">
                                <div class="form-label">{{ loginTabs == 0 ? $t('I9') : $t('I10') }}</div>
                                <div class="form-val f-row-s-c">
                                    <!-- <el-select v-if="loginTabs == 0" v-model="areaCode" class="common-select area-select"
                                        :placeholder="$t('N11')">
                                        <el-option v-for="item in areaList" :key="item.id" :label="'+' + item.areaCode"
                                            :value="item.areaCode">
                                        </el-option>
                                    </el-select> -->
                                    <el-input v-model="login.inputVal"
                                        :placeholder="loginTabs == 0 ? $t('I1') : $t('I20')"></el-input>
                                </div>
                            </div>
                            <div class="form-input">
                                <div class="form-label">{{ $t('I21') }}</div>
                                <div class="form-val">
                                    <el-input v-model="login.password" :placeholder="$t('I2')" show-password></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="full-btn">
                            <button class="btn" :disabled="disabledLogin" @click="handleLogin">{{ $t('I3') }}</button>
                        </div>
                        <div class="fill-tips f-row-b-c">
                            <div class="tit">{{ $t('N13') }}<span @click="goRegister">{{ $t('N14') }}</span></div>
                            <div class="tits" @click="goForgetPass">{{ $t('I5') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 重置密码弹窗 -->
        <el-dialog :fullscreen="true" :modal="false" :visible.sync="$store.state.showPass" :show-close="false">
            <div class="full-dialog">
                <div class="full-cover f-row-e-c">
                    <div class="full-box f-col-c-c">
                        <div class="full-close" @click="closeDialog(2)">
                            <img src="../assets/images/login_icon_close.png" alt="">
                        </div>
                        <div class="full-contain">
                            <div class="full-title f-row-s-c">
                                <div class="tabs one-txt-cut" :class="{ 'active': passTabs == 0 }"
                                    @click="changePassTabs(0)">
                                    {{ $t('N17') }}
                                </div>
                                <div class="tabs one-txt-cut" :class="{ 'active': passTabs == 1 }"
                                    @click="changePassTabs(1)">
                                    {{ $t('N18') }}
                                </div>
                            </div>
                            <div class="full-form">
                                <div class="form-val f-row-s-c">
                                    <el-select v-if="passTabs == 0" v-model="areaCode" class="common-select area-select"
                                        :placeholder="$t('N11')">
                                        <el-option v-for="item in areaList" :key="item.id" :label="'+' + item.areaCode"
                                            :value="item.areaCode">
                                        </el-option>
                                    </el-select>
                                    <el-input v-model="pass.inputVal"
                                        :placeholder="registerTabs == 0 ? $t('I1') : $t('I20')"></el-input>
                                </div>
                                <div class="form-val f-row-b-c">
                                    <el-input v-model="pass.code" :placeholder="$t('I17')"></el-input>
                                    <div v-if="showCode" class="codes" @click="getCode('pass', passTabs)">
                                        {{ $t('I18') }}
                                    </div>
                                    <div v-else class="codes">{{ times }}s{{ $t('I19') }}</div>
                                </div>
                                <div class="form-val">
                                    <el-input v-model="pass.password" show-password :placeholder="$t('M52')"></el-input>
                                </div>
                                <div class="form-val">
                                    <el-input v-model="pass.newpassword" show-password
                                        :placeholder="$t('M53')"></el-input>
                                </div>
                            </div>
                            <div class="full-btn">
                                <button class="btn" :disabled="disabledPass" @click="handlePass">{{ $t('M54')
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 注册下一步弹窗 -->
        <el-dialog :fullscreen="true" :modal="false" :visible.sync="$store.state.showRegister" :show-close="false">
            <div class="full-dialog">
                <div class="full-cover f-row-e-c">
                    <div class="full-box f-col-c-c">
                        <div class="full-close" @click="closeDialog(3)">
                            <img src="../assets/images/login_icon_close.png" alt="">
                        </div>
                        <div class="full-contain">
                            <div class="full-title f-row-s-c">
                                <div class="tabs one-txt-cut" :class="{ 'active': registerTabs == 0 }"
                                    @click="changeRegisterTabs(0)">
                                    {{ $t('I7') }}
                                </div>
                                <div class="tabs one-txt-cut" :class="{ 'active': registerTabs == 1 }"
                                    @click="changeRegisterTabs(1)">
                                    {{ $t('I8') }}
                                </div>
                            </div>
                            <div class="full-form">
                                <div class="form-val f-row-s-c">
                                    <el-select v-if="registerTabs == 0" v-model="areaCode"
                                        class="common-select area-select" :placeholder="$t('N11')">
                                        <el-option v-for="item in areaList" :key="item.id" :label="'+' + item.areaCode"
                                            :value="item.areaCode">
                                        </el-option>
                                    </el-select>
                                    <el-input v-model="register.inputVal"
                                        :placeholder="registerTabs == 0 ? $t('I1') : $t('I20')"></el-input>
                                </div>
                                <div class="form-val f-row-b-c">
                                    <el-input v-model="register.code" :placeholder="$t('I17')"></el-input>
                                    <div v-if="showCode" class="codes" @click="getCode('register', registerTabs)">
                                        {{ $t('I18') }}
                                    </div>
                                    <div v-else class="codes">{{ times }}s{{ $t('I19') }}</div>
                                </div>
                                <div class="form-val">
                                    <el-input v-model="register.password" show-password
                                        :placeholder="$t('M52')"></el-input>
                                </div>
                                <div class="form-val">
                                    <el-input v-model="register.newpassword" show-password
                                        :placeholder="$t('M53')"></el-input>
                                </div>
                            </div>
                            <div class="full-btn">
                                <button class="btn" :disabled="disabledRegister" @click="handleRegister">{{ $t('I25')
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 注册提交审核弹窗 -->
        <el-dialog :fullscreen="true" :modal="false" :visible.sync="$store.state.showDatum" :show-close="false">
            <div class="full-dialog">
                <div class="full-cover f-row-e-c">
                    <div class="full-box f-col-s-c" style="height:1000px;">
                        <div class="full-close" @click="closeDialog(4)">
                            <img src="../assets/images/login_icon_close.png" alt="">
                        </div>
                        <div class="full-contain">
                            <div class="full-form" style="padding-top:96px;">
                                <div class="form-val">
                                    <el-input v-model="check.enterpriseName"
                                        :placeholder="$t('N40')"></el-input>
                                </div>
                                <div class="form-row f-row-s-c">
                                    <div class="form-val ones">
                                        <el-input v-model="check.contactName"
                                            :placeholder="$t('Text2')"></el-input>
                                    </div>
                                    <div class="form-val twos">
                                        <el-input v-model="check.contactMobile"
                                            :placeholder="$t('N21')"></el-input>
                                    </div>
                                </div>
                                <div class="form-row f-row-s-c">
                                    <div class="form-val ones">
                                        <el-input v-model="check.contactEmail"
                                            :placeholder="$t('Text3')"></el-input>
                                    </div>
                                    <div class="form-val twos">
                                        <!-- <el-cascader ref="cascaderAddr" @change="changeAddress" class="w100 h100"
                                            :placeholder="$t('I15') + $t('I14')" :props="propsAddress" v-model="addressVal"
                                            :options="addressList"></el-cascader> -->
                                        <el-input v-model="check.enterpriseAddress"
                                            :placeholder="$t('Text4')"></el-input>
                                    </div>
                                </div>
                                <div class="form-val">
                                    <el-input v-model="check.detailAddress"
                                        :placeholder="$t('Text5')"></el-input>
                                </div>
                                <div class="form-row f-row-s-c">
                                    <div class="form-val ones">
                                        <el-input v-model="check.openBank"
                                            :placeholder="$t('Text6')"></el-input>
                                    </div>
                                    <div class="form-val twos">
                                        <el-input v-model="check.payee" :placeholder="$t('Text7')"></el-input>
                                    </div>
                                </div>
                                <div class="form-val">
                                    <el-input v-model="check.bankCardNumber"
                                        :placeholder="$t('Text8')"></el-input>
                                </div>
                                <div class="form-val">
                                    <el-input v-model="check.enterpriseOperateRange"
                                        :placeholder="$t('Text9')"></el-input>
                                </div>
                                <div class="">
                                    <el-upload class="avatar-uploader"
                                        :action="httpUrl + '/fangshan/dm-user/uploadAppOSS'" :show-file-list="false"
                                        :on-success="success" :before-upload="beforeAvatarUpload" :on-error="error">
                                        <img v-if="check.businessLicense" :src="check.businessLicense"
                                            class="avatar-img">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    <p style="margin-left: 8px; margin-top: 10px; font-size: 16px;">{{ $t('I31') }}</p>
                                </div>
                            </div>
                            <div class="full-btn">
                                <button class="btn" @click="handleCheck">
                                    {{ $t('I25') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import $api from '@/api/api.js'
import { addresss } from '@/api/address.js'
import httpUrl from '@/plugins/axios/url'
export default {
    props: {
        //显示样式
        showStyle: {
            type: String,
            default: 'black',//black white
        },
    },
    data() {
        return {
            httpUrl: httpUrl.baseUrl,//域名

            languageVal: 'zh',
            languageList: [{
                value: "zh",
                label: "中文",
            }, {
                value: "en",
                label: "English",
            }],
            isLogin: false,//是否已登录
            navActive: null,//当前导航样式
            //账号信息
            userInfo: {
                mobile: ''
            },

            addressList: [],//省市区数据列表
            addressVal: [],//选择值
            currentArr: [],
            //定义级联参数
            propsAddress: {
                value: 'code',
                label: 'value',
                children: 'children',
            },

            areaCode: "",//手机区号值
            areaList: [],//手机区号列表
            showCode: true, //发送验证码状态
            times: 60,//倒计时 秒

            loginTabs: 0,//当前登录类型
            passTabs: 0,//当前密码找回类型
            registerTabs: 0,//当前注册类型

            //登录信息
            login: {
                inputVal: "",
                password: ""
            },
            //重置密码信息
            pass: {
                inputVal: "",
                code: "",
                password: "",
                newpassword: "",
            },
            //注册信息
            register: {
                inputVal: "",
                code: "",
                password: "",
                newpassword: "",
            },
            //注册提交审核信息
            check: {
                enterpriseName: "",//企业名称
                contactName: "",//联系人姓名
                contactMobile: "",//联系人电话
                contactEmail: "",//联系人邮箱
                enterpriseAddress: '',//企业所在地
                detailAddress: "",//详细地址
                openBank: "",//开户行
                payee: "",//收款人
                bankCardNumber: "",//银行卡号
                enterpriseOperateRange: "",//企业经营范围
                businessLicense: "",//营业执照图片

                businessLicenseNo: "",//营业执照号码
            },
        }
    },
    computed: {
        disabledLogin() {
            for (let key in this.login) {
                if (this.login[key] === '') {
                    return true;
                }
            }
            return false;
        },
        disabledPass() {
            for (let key in this.pass) {
                if (this.pass[key] === '') {
                    return true;
                }
            }
            return false;
        },
        disabledRegister() {
            for (let key in this.register) {
                if (this.register[key] === '') {
                    return true;
                }
            }
            return false;
        },
    },
    created() {
        this.addressList = addresss;
        this.getPhoneAreaList()

        if (localStorage.getItem('language')) {
            this.$i18n.locale = localStorage.getItem('language');
            this.languageVal = localStorage.getItem('language');
        }

        if (localStorage.getItem('FIRM_TOKEN')) {
            this.getUserInfo()
            this.isLogin = true
        } else {
            localStorage.clear()
        }
    },
    mounted() {
        this.navActive = sessionStorage.getItem('ACTIVE_MENU');
    },
    methods: {
        //切换语言
        changeLanguage(e) {
            this.$nextTick(() => {
                this.$i18n.locale = e;
                localStorage.setItem('language', e)
                location.reload();
            });
        },
        //获取当前账号信息
        getUserInfo() {
            $api.getEnterpriseUserExtInfo().then(res => {
                if (res.code == 200) {
                    this.userInfo = res.data || {}
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取手机区号列表
        getPhoneAreaList() {
            $api.getAreaCodeList().then(res => {
                if (res.code == 200) {
                    this.areaList = res.data || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //设置当前导航样式
        navBar(id) {
            this.navActive = id;
            sessionStorage.setItem('ACTIVE_MENU', id);
        },
        //logo跳转
        goHome() {
            window.location.href = "/"
        },
        //选择所在地省市区
        changeAddress() {
            const regionList = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
            this.check.enterpriseAddress = regionList ? regionList.join('/') : '';
        },
        //退出登录
        handleBack() {
            this.$confirm(this.$t('N19'), this.$t('N20'), {
                confirmButtonText: this.$t('M24'),
                cancelButtonText: this.$t('M86'),
                type: 'warning'
            }).then(() => {
                //localStorage.removeItem('FIRM_TOKEN')
                localStorage.clear()
                this.isLogin = false
                this.$message.success(this.$t('M61'))
                window.location.href = "/"
            }).catch(() => { })
        },
        //切换登录tabs
        changeLoginTabs(tabs) {
            this.loginTabs = tabs
        },
        //切换注册tabs
        changeRegisterTabs(tabs) {
            this.registerTabs = tabs
            this.register = {
                inputVal: "",
                code: "",
                password: "",
                newpassword: "",
            }
        },
        //切换密码找回tabs
        changePassTabs(tabs) {
            this.passTabs = tabs
            this.pass = {
                inputVal: "",
                code: "",
                password: "",
                newpassword: "",
            }
        },
        /**
         * @decration 获取验证码 
         * @params popup {string} register 注册弹窗  pass 找回弹窗--重置密码
         * @params type {number} 0手机号 1邮箱
        */
        getCode(popup, type) {
            let verify = ''//验证规则
            let dataParams = {}

            if (type == 0) {
                if (!this.areaCode) {
                    this.$message.error(this.$t('N11'))
                    return
                }
                //手机号
                verify = /^1[3-9]{1}[0-9]{9}$/
                dataParams = {
                    areaCode: this.areaCode,
                    mobile: this[popup].inputVal,
                    registerType: 1,//1手机 2邮箱
                }
            } else if (type == 1) {
                //邮箱
                verify = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                dataParams = {
                    email: this[popup].inputVal,
                    registerType: 2,//1手机 2邮箱
                }
            }
            //if (verify.test(this[popup].inputVal)) {
            $api.sendAccountCode(dataParams).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.showCode = false
                    let timer = setInterval(() => {
                        if (this.times > 0) {
                            this.times--
                        } else {
                            this.showCode = true
                            this.times = 60
                            clearInterval(timer)
                        }
                    }, 1000)
                } else {
                    this.$message.error(res.msg)
                }
            })
            // } else {
            //     this.$message.error(this.$t('N12'))
            // }
        },
        //登录
        handleLogin() {
            $api.appLogin({
                [this.loginTabs == 0 ? 'mobile' : 'email']: this.login.inputVal,
                password: this.login.password,
                registerType: this.loginTabs == 0 ? 1 : 2,//1手机 2邮箱
            }).then(res => {
                if (res.code == 200) {
                    localStorage.setItem('FIRM_TOKEN', res.data)
                    this.isLogin = true
                    this.$message.success(this.$t('N15'))
                    this.login = {
                        inputVal: "",
                        password: ""
                    }
                    setTimeout(() => {
                        this.$store.commit('NOSHOWLOGIN')
                    }, 500);
                    this.getUserInfo()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //重置密码
        handlePass() {
            if (!this.areaCode) {
                this.$message.error(this.$t('N11'))
                return
            }
            if (this.pass.password != this.pass.newpassword) {
                this.$message.warning(this.$t('M59'))
                return
            }
            $api.forgotPassword({
                [this.passTabs == 0 ? 'mobile' : 'email']: this.pass.inputVal,
                identifyCode: this.pass.code,
                password: this.pass.password,
                registerType: this.passTabs == 0 ? 1 : 2,//1手机 2邮箱
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(this.$t('M60'))
                    this.pass = {
                        inputVal: "",
                        code: "",
                        password: "",
                        newpassword: "",
                    }
                    setTimeout(() => {
                        this.$store.commit('NOSHOWPASS')
                        this.$store.commit('SHOWLOGIN')
                    }, 500);
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //注册--下一步
        handleRegister() {
            this.$store.commit('SHOWDATUM')
            if (this.registerTabs == 0) {
                if (!this.areaCode) {
                    this.$message.error(this.$t('N11'))
                    return
                }
            }
            if (this.register.password != this.register.newpassword) {
                this.$message.warning(this.$t('M59'))
                return
            }
            $api.checkIdentifyCode({
                [this.registerTabs == 0 ? 'mobile' : 'email']: this.register.inputVal,
                identifyCode: this.register.code,
                registerType: this.registerTabs == 0 ? 1 : 2,//1手机 2邮箱
            }).then(res => {
                if (res.code == 200) {
                    // this.register = {
                    //     inputVal: "",
                    //     code: "",
                    //     password: "",
                    //     newpassword: "",
                    // }
                    setTimeout(() => {
                        this.$store.commit('NOSHOWREGISTER')
                        this.$store.commit('SHOWDATUM')
                    }, 500);
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //注册--提交审核
        handleCheck() {
            let dataParams = {}
            let params = {}
            params = {
                [this.registerTabs == 0 ? 'mobile' : 'email']: this.register.inputVal,
                password: this.register.password,
                registerType: this.registerTabs == 0 ? 1 : 2,//1手机 2邮箱
            }
            Object.assign(dataParams, params, this.check)
            $api.register(dataParams).then(res => {
                if (res.code == 200) {
                    this.$message.success(this.$t('N16'))
                    setTimeout(() => {
                        this.$store.commit('NOSHOWDATUM')
                    }, 500);
                } else {
                    this.$message.error(res.msg)
                }
            })
        },

        //关闭弹窗
        closeDialog(type) {
            if (type == 1) {
                this.$store.commit('NOSHOWLOGIN')
            } else if (type == 2) {
                this.$store.commit('NOSHOWPASS')
            } else if (type == 3) {
                this.$store.commit('NOSHOWREGISTER')
            } else if (type == 4) {
                this.$store.commit('NOSHOWDATUM')
            }
        },
        //跳转到注册
        goRegister() {
            this.$store.commit('NOSHOWLOGIN')
            this.$store.commit('SHOWREGISTER')
        },
        //跳转到忘记密码
        goForgetPass() {
            this.$store.commit('NOSHOWLOGIN')
            this.$store.commit('SHOWPASS')
        },
        //跳转到登录
        gologin() {
            this.$store.commit('NOSHOWREGISTER')
            this.$store.commit('SHOWLOGIN')
        },

        //上传成功
        success(response) {
            this.$message.success(this.$t('M63'))
            this.check.businessLicense = response.data.url
        },
        //上传后的钩子，限制大小
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error(this.$t('M64'));
            }
            return isLt2M;
        },
        //上传失败
        error(err, file) {
            this.$message.error(this.$t('M65'));
        },
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width:1560px) {
    .head-wrap {
        width: 1200px !important;
    }
}

.head-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 96px;
    background: #0000004d;

    .head-wrap {
        margin: auto;
        width: 1560px;
        height: 100%;
    }

    .languages {
        width: 100px;
        height: 40px;

        ::v-deep .el-select {
            margin-top: -2px;
        }

        ::v-deep .el-input__inner {
            color: #fff !important;
            background: transparent !important;
            border: none !important;
        }
    }
}

.nav-list {
    gap: 22px;
    font-size: 14px;
    color: #fff;

    .item {
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        user-select: none;

        &:hover {
            font-weight: bold;
        }
    }
}

//其他样式
.head-white {
    background: #fff;
    box-shadow: 0 0 3px 3px #eee;

    .languages {
        width: 100px;
        height: 40px;

        ::v-deep .el-input__inner {
            color: #333 !important;
        }
    }

    .nav-list {
        color: #333;

        .item {
            color: #333;
        }
    }
}

.head-null {
    background: transparent;
}

//全屏弹窗
.full-dialog {
    height: 100vh;
    background: url('../assets/images/login_bg.png') no-repeat center center/cover;
    background-attachment: fixed;
    overflow-y: auto;

    ::v-deep .el-input__inner {
        border: none !important;
    }

    .full-cover {
        padding: 50px 0;
        min-height: 100vh;
    }

    .full-box {
        position: relative;
        margin-right: 90px;
        width: 762px;
        height: 734px;
        background: #fff;
        border-radius: 20px;

        .full-close {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
        }

        .full-contain {
            width: 634px;
        }
    }

    .full-title {
        margin-bottom: 46px;
        line-height: 80px;
        font-size: 38px;

        .tabs {
            margin-right: 30px;
            color: #A4A4A4;
            cursor: pointer;
        }

        .active {
            position: relative;
            font-weight: bold;
            color: #333;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 52px;
                height: 6px;
                background: var(--theme-color);
            }
        }
    }

    .form-label {
        padding: 22px 0 28px;
        font-weight: bold;
        font-size: 22px;
        color: #333;
    }

    .form-row {
        gap: 16px;

        .ones {
            width: 280px;
        }

        .twos {
            width: 338px;
        }
    }

    .form-val {
        position: relative;
        margin-bottom: 16px;
        height: 76px;
        border: 1px solid #dadada;
        border-radius: 4px;

        .codes {
            flex: 0 0 140px;
            font-size: 22px;
            color: var(--theme-color);
            cursor: pointer;
        }

        .area-select {
            width: 200px;
            height: 100%;
            border: none;
        }
    }

    .full-txts {
        font-size: 16px;
        color: #999;

        span {
            color: var(--theme-color);
            cursor: pointer;
        }
    }

    .full-btn {
        padding: 40px 0 30px;

        .btn {
            padding: 0;
            width: 100%;
            height: 94px;
            line-height: 94px;
        }
    }

    .fill-tips {
        font-size: 22px;
        color: #333;

        span {
            color: var(--theme-color);
            cursor: pointer;
        }

        .tits {
            cursor: pointer;
        }
    }
}

.avatar-uploader {
    ::v-deep .el-upload {
        position: relative;
        border-radius: 6px;
        border: 1px dashed #d9d9d9;
        cursor: pointer;
        overflow: hidden;
    }

    .avatar-uploader-icon {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 28px;
        color: #8c939d;
        text-align: center;
    }

    .avatar-img {
        display: block;
        width: 80px;
        height: 80px;
    }
}
</style>