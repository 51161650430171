import { $http } from '../plugins/axios'
const { get, post, deleted } = $http

export default {
  platFormTextType: (params) => get(`/fangshan/dm-user/platFormTextType`, params), //服务协议/隐私政策/平台介绍
  //登录
  appLogin: (params) => post(`/api/system/enterpriseUser/appLogin`, params), //登录
  forgotPassword: (params) => post(`/api/system/enterpriseUser/forgotPassword`, params), //忘记密码
  checkIdentifyCode: (params) => post(`/api/system/enterpriseUser/checkIdentifyCode`, params), //校验验证码--注册下一步
  register: (params) => post(`/api/system/enterpriseUser/register`, params), //注册
  sendAccountCode: (params) => post(`/api/system/enterpriseUser/sendAccountCode`, params), //发送验证码
  getAreaCodeList: (params) => get(`/fangshan/dm-order/listAllCode`, params), //手机号区号列表

  //下单
  listCharterTime: (params) => get(`/api/system/enterpriseUser/listCharterTime`, params), //用车时长列表
  distanceCalculation: (params) => get(`/system/enterpriseOrder/distanceCalculation`, params), //计算距离 专车
  pageAllCarAddress: (params) => get(`/api/system/enterpriseUser/pageAllCarByAddress`, params), //分页车型信息列表 专车
  listCharterTimeNew: (params) => get(`/api/system/enterpriseUser/pageAllCarByTime`, params), //分页车型信息列表 包车
  pageAllCarService: (params) => get(`/api/system/enterpriseUser/pageAllCarService`, params), //平台服务信息
  orderGeneration: (params) => post(`/system/enterpriseOrder/orderGeneration`, params), //生成订单 专车
  charteredBusOrderGeneration: (params) => post(`/system/enterpriseOrder/charteredBusOrderGeneration`, params), //生成订单 包车
  addressSearch: (params) => get(`/fangshan/dm-new/getAddressByGoogle`, params), //地址搜索范围 中文
  addressSearchEnglish: (params) => get(`/fangshan/dm-new/getAddressByGoogleForEnglish`, params), //地址搜索范围 英文
  getOverTimeInfo: (params) => get(`/fangshan/dm-user/platFormTextType`, params), //超时说明 专车
  setWxPay: (params) => post(`/fangshan/pay/codePay`, params), //微信支付
  setzfbPay: (params) => post(`/fangshan/pay/AlipayScanPay`, params), //支付宝支付
  setCodePayEnd: (params) => get(`/fangshan/pay/codePayEnd`, params), //判断是否支付
  
  //订单
  pageAllEnterpriseOrder: (params) => get(`/system/enterpriseOrder/pageAllEnterpriseOrder`, params), //分页我的订单列表
  getEnterpriseOrderDetail: (params) => get(`/system/enterpriseOrder/getEnterpriseOrderDetail`, params), //订单详情
  cancelOrder: (params) => post(`/system/enterpriseOrder/cancelOrder?id=${params}`), //取消订单
  delOrder: (params) => post(`/system/enterpriseOrder/delOrder?id=${params}`), //删除订单

  //企业信息
  getEnterpriseUserExtInfo: (params) => get(`/api/system/enterpriseUser/getEnterpriseUserExtInfo`, params), //获取企业信息
  editEnterpriseInfo: (params) => post(`/api/system/enterpriseUser/editEnterpriseInfo`, params), //更改企业信息

  //推广中心
  generateChannelLin: (params) => post(`/api/system/enterpriseUser/generateChannelLink`, params), //生成渠道专属链接
  pageAllPromotionLink: (params) => get(`/api/system/enterpriseUser/pageAllPromotionLink`, params), //分页推广链接列表
  delPromotionLink: (params) => deleted(`/api/system/enterpriseUser/delPromotionLink/${params}`), //删除推广链接信息

  //财务中心
  getEnterpriseFinanceInfo: (params) => get(`/api/system/enterpriseUser/getEnterpriseFinanceInfo`, params), //企业用户财务金额信息
  pageAllFinancialDetails: (params) => get(`/api/system/enterpriseUser/pageAllFinancialDetails`, params), //分页企业用户财务明细列表

  //设置
  getUserMessage: (params) => get(`/api/system/enterpriseUser/getUserMessage`, params), //获取用户邮箱或者手机号
  sendAccountCode: (params) => post(`/api/system/enterpriseUser/sendAccountCode`, params), //发送验证码
  replaceAccount: (params) => post(`/api/system/enterpriseUser/replaceAccount`, params), //更换手机号或者更换邮箱
  replacePassword: (params) => post(`/api/system/enterpriseUser/replacePassword`, params), //修改登录密码

  //专属链接
  generateQRCode: (params) => post(`/api/system/enterpriseUser/generateQRCode`, params), //生成二维码
  getQRCode: (params) => get(`/api/system/enterpriseUser/getQRCode`, params), //获取二维码

}